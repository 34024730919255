<template>
  <v-form @submit.prevent="submitResponse">
    <p>
      Complete the table by filling in the number of protons, electrons, and neutrons for the
      following isotopes of oxygen.
    </p>
    <v-simple-table>
      <template>
        <thead>
          <tr>
            <th style="border-bottom-width: 0">Part</th>
            <th style="border-bottom-width: 0">Isotope</th>
            <th style="border-bottom-width: 0">Protons</th>
            <th style="border-bottom-width: 0">Electrons</th>
            <th style="border-bottom-width: 0">Neutrons</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(part, index) in ['a', 'b', 'c']" :key="part">
            <td style="border-bottom-width: 0">{{ part + ')' }}</td>
            <td style="border-bottom-width: 0">
              <chemical-latex :content="'^' + (index + 16) + 'O'" />
            </td>
            <td
              v-for="input in ['protons', 'electrons', 'neutrons']"
              :key="input"
              style="border-bottom-width: 0"
            >
              <v-text-field v-model="inputs[part + '_' + input]"></v-text-field>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'DeterminingPrNeElGrader',
  components: {StembleLatex, ChemicalLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        a_protons: null,
        a_electrons: null,
        a_neutrons: null,
        b_protons: null,
        b_electrons: null,
        b_neutrons: null,
        c_protons: null,
        c_electrons: null,
        c_neutrons: null,
      },
    };
  },
};
</script>

<style scoped></style>
